import { defineStore } from "pinia";
import api from "@/services/useApi";

export const useCheckoutStore = defineStore("checkout", {
  state: () => ({
    purchasedProduct: null,
    appAccess: localStorage.getItem("appAccess") === "true",
  }),
  actions: {
    async createOrder(orderData) {
      const res = await api.post("/orders", orderData);

      return res.data.order;
    },
    async updateOrder(orderId, orderData) {
      const res = await api.patch(`/orders/${orderId}`, orderData);

      return res.data.order;
    },
    async applyCoupon(orderId, coupon) {
      const res = await api.patch(`/orders/${orderId}/coupons`, {
        coupons: [{ code: coupon }],
      });

      return res.data.order;
    },
    async applyGiftCard(orderId, cardNumber) {
      const res = await api.patch(`/orders/${orderId}/gift-card`, {
        cardNumber,
      });

      return res.data.order;
    },
    async payOrder(orderId, paymentData) {
      const res = await api.patch(`/orders/${orderId}/finalize`, paymentData);

      return res.data;
    },

    setPurchasedProduct(purchasedProductData) {
      this.purchasedProduct = purchasedProductData;
    },
    clearPurchasedProduct() {
      this.purchasedProduct = null;
    },
  },
});
