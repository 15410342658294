<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";

import api from "@/services/useApi";

import SVGIcon from "@/components/SVGIcon.vue";
import MobileNavigation from "./MobileNavigation.vue";

import { useLinks } from "./links";

const cart = ref(0);
const navBackgroundColor = ref("transparent");
const showMenu = ref(false);
const navLinks = useLinks();

const currentRoute = computed(() => {
  return useRouter()?.currentRoute?.value.path;
});

const route = useRoute();
watch(route, () => {
  showMenu.value = false;
});

const openMenu = () => {
  showMenu.value = !showMenu.value;
};

onMounted(() => {
  api
    .get("/orders/cart", {
      cart: getStratoCookie(),
    })
    .then((data) => {
      cart.value = data?.data?.data;
    })
    .catch((err) => console.error(err));
});

const getStratoCookie = () => {
  if (document.cookie) {
    return document.cookie
      .split(";")
      .map((v) => v.split("="))
      .reduce((acc, v) => {
        acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
        return acc;
      }, {})["strato-cart"];
  }
  return 0;
};
</script>

<template>
  <nav
    ref="nav"
    class="section navbar"
    :style="{
      backgroundColor: navBackgroundColor,
    }"
  >
    <div class="navbar-container">
      <div class="logo-icons">
        <router-link class="w2w-logo" to="/" aria-label="home page">
          <SVGIcon width="40" height="40" name="logo" />
          <SVGIcon
            class="ways2well-text"
            name="ways2well"
            width="109px"
            height="22px"
          />
        </router-link>
        <router-link class="get-started" to="/get-started">
          <button class="button primary" type="button">GET STARTED</button>
        </router-link>
        <div class="mobile-buttons">
          <a href="https://shop.ways2well.com/s/cart" aria-label="cart">
            <SVGIcon name="cart" width="20" height="20" />
          </a>
          <div class="menu-icon-container" @click="openMenu">
            <div class="menu-icon hamburger">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
      <ul class="navbar-links">
        <li v-for="link in navLinks" :key="link.text">
          <component
            :is="link.url?.startsWith('https://') ? 'a' : 'router-link'"
            v-if="!link.childLinks"
            :to="link.url"
            :href="link.url"
          >
            <div
              class="navbar-links-item heading-7 text-dark"
              :class="{
                current: currentRoute === link.url,
              }"
            >
              {{ link.text }}
            </div>
          </component>

          <div v-else class="navbar-dropdown">
            <div class="navbar-links-item heading-7 text-dark">
              {{ link.text }}
              <SVGIcon
                class="chevron"
                name="chevron-down"
                width="20"
                height="20"
              />
            </div>
            <div class="navbar-dropdown-content-wrapper">
              <div class="navbar-dropdown-content">
                <div v-for="childLink in link.childLinks" :key="childLink.text">
                  <router-link :to="childLink.url">
                    <div
                      class="dropdown-item heading-7 text-dark"
                      :class="{
                        current: currentRoute === childLink.url,
                      }"
                    >
                      {{ childLink.text }}
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="desktop-buttons">
        <a
          class="button secondary phone"
          href="/support/contact-us/#allen-live-chat"
        >
          Live Chat
        </a>
        <a
          class="button secondary"
          href="https://phr.charmtracker.com/login.sas?FACILITY_ID=aad7261cfb8b45301322e1fe377750f3e20ad6f361c0ce182e62fe42203ae47d7be1cfd797c1e554"
        >
          LOG IN
        </a>
        <a
          class="button secondary cart-button"
          aria-label="cart"
          href="https://shop.ways2well.com/s/cart"
        >
          <SVGIcon class="cart" name="cart" width="20" height="20" />
        </a>
      </div>
    </div>

    <mobile-navigation v-model="showMenu" />
  </nav>
</template>

<style scoped src="./style.scss" lang="scss" />
