<script setup>
import { computed, ref } from "vue";
import { useRouter, RouterLink } from "vue-router";
import SVGIcon from "@components/SVGIcon.vue";
import SimpleModal from "@components/redesign/Modals/SimpleModal/SimpleModal.vue";
import { useLinks } from "./links";

const props = defineProps({
  modelValue: Boolean,
});
const emit = defineEmits(["update:modelValue"]);

const navLinks = useLinks();
const selectedParent = ref("");
const selectedChild = ref("");

const model = computed({
  get() {
    if (props.modelValue) findSelectedLinks();

    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const findSelectedLinks = () => {
  const currentPath = useRouter().currentRoute.value.path;

  const parent = navLinks.value.find((link) => {
    if (link.url === currentPath) {
      selectedChild.value = link.text;
      return true;
    }
    if (link.childLinks) {
      const child = link.childLinks.find(
        (childLink) => childLink.url === currentPath
      );
      if (child) {
        selectedChild.value = child.text;
        return true;
      }
    }
    return false;
  });

  selectedParent.value = parent?.text || "";
};
</script>

<template>
  <SimpleModal v-model="model">
    <div class="navigation-content">
      <div class="nav-header">
        <router-link class="nav-header-logo" to="/">
          <SVGIcon class="logo" name="logo" />
          <SVGIcon name="ways2well" width="109px" height="22px" />
        </router-link>
      </div>

      <div class="nav-buttons">
        <router-link to="/get-started">
          <button class="button primary" type="button">Get started</button>
        </router-link>

        <a href="/support/contact-us/#allen-live-chat">
          <button class="button secondary" type="button">Live Chat</button>
        </a>

        <div class="split">
          <a href="https://shop.ways2well.com/s/cart">
            <button class="button secondary" type="button">
              <SVGIcon width="24" height="24" name="cart" />
            </button>
          </a>

          <a
            href="https://phr.charmtracker.com/login.sas?FACILITY_ID=aad7261cfb8b45301322e1fe377750f3e20ad6f361c0ce182e62fe42203ae47d7be1cfd797c1e554"
          >
            <button class="button secondary" type="button">Log In</button>
          </a>
        </div>
      </div>

      <ul class="nav-links">
        <li v-for="link in navLinks" :key="link.text">
          <component
            :is="link.url?.startsWith('https://') ? 'a' : 'router-link'"
            v-if="!link.childLinks"
            :to="link.url"
            :href="link.url"
          >
            <div
              class="nav-links-item heading-7 text-dark"
              :class="{
                'selected-child': selectedChild === link.text,
              }"
            >
              {{ link.text }}
            </div>
          </component>

          <div v-else>
            <div
              class="nav-links-item heading-7 text-dark"
              :class="{ 'selected-parent': selectedParent === link.text }"
              @click="
                selectedParent = selectedParent === link.text ? '' : link.text
              "
            >
              {{ link.text }}
              <SVGIcon
                class="chevron"
                name="chevron-down"
                width="20"
                height="20"
              />
            </div>
            <ul
              class="nav-links children"
              :class="{ expanded: selectedParent === link.text }"
            >
              <li v-for="childLink in link.childLinks" :key="childLink.text">
                <router-link :to="childLink.url">
                  <div
                    class="nav-links-item heading-7 text-dark"
                    :class="{
                      'selected-child': selectedChild === childLink.text,
                    }"
                    @click="
                      selectedChild =
                        selectedChild === childLink.text ? '' : childLink.text
                    "
                  >
                    {{ childLink.text }}
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </SimpleModal>
</template>

<style src="./style.scss" lang="scss" scoped></style>
