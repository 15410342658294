import { useAuthUserStore } from "@/stores/authUser";
import { useCheckoutStore } from "@/stores/checkout";

export async function authenticateUser(to, from, next) {
  const authUserStore = useAuthUserStore();
  const checkoutStore = useCheckoutStore();
  // console.log("authenticateUser to.query", to.query);

  if (!to.query.token) {
    console.error("No token provided");
    next("/");
  } else if (!to.query.redirect) {
    console.error("No redirect provided");
    next("/");
  } else {
    // console.log("Setting auth user data", to.query.token);
    authUserStore.setAuthUserData(to.query.token);

    // Safely handle the redirect
    const decodedRedirect = decodeURIComponent(to.query.redirect);
    const safeRedirect = getSafeRedirectPath(decodedRedirect);

    const appAccess = to.query?.appAccess === "true";
    localStorage.setItem("appAccess", appAccess);
    checkoutStore.appAccess = appAccess;

    next(safeRedirect);
  }
}

// Simplified helper function
function getSafeRedirectPath(redirect) {
  // If it starts with '/', it's already a relative path
  if (redirect.startsWith("/")) {
    return redirect;
  }

  try {
    const url = new URL(redirect, window.location.origin);
    // Only allow redirects to the same origin
    if (url.origin === window.location.origin) {
      return url.pathname + url.search + url.hash;
    }
  } catch (e) {
    // Invalid URL, ignore
  }

  // Default to home page if the redirect is not safe
  return "/";
}

export function formatDollars(value) {
  return value && `$${parseFloat(value).toFixed(2)}`;
}

export function capitalizeHelper(str) {
  if (typeof str !== "string") return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function calcPercent(original, reduced) {
  return original && reduced
    ? `${Math.floor(((original - reduced) / original) * 100)}%`
    : "";
}

export function objectToArray(obj) {
  return Object.keys(obj).map(function (key) {
    return obj[key];
  });
}

export function parsePrivateProductId(product) {
  if (product.item_data.ecom_uri) {
    // product uri available
    const match = product.item_data.ecom_uri.match(/\/(\d+)$/);
    if (match) {
      return match[1];
    }
  } else if (product.item_data.ecom_image_uris) {
    // hidden product - only image uris available
    let consistentIds = true;
    let lastId = null;

    // check if all image uris have the same product id
    for (const uri of product.item_data.ecom_image_uris) {
      const matchedId = (uri.match(/_p(\d+)_/) || [])[1];

      if (matchedId) {
        if (lastId !== null && lastId !== matchedId) {
          consistentIds = false;
          break;
        }
        lastId = matchedId;
      }
    }

    if (consistentIds && lastId !== null) {
      return lastId;
    }
  } else {
    return null;
  }
}

export function updateMetaTags(document, tags) {
  // console.log("updateMetaTags");

  // Add new meta tags
  if (tags) {
    tags.forEach((tagDef) => {
      let tag;
      // If the new tag has a 'name' or 'property' attribute, update the existing tag
      if (tagDef.name) {
        tag = document.querySelector(`meta[name='${tagDef.name}']`);
      } else if (tagDef.property) {
        tag = document.querySelector(`meta[property='${tagDef.property}']`);
      }

      if (tag) {
        // Update the 'content' attribute of the existing tag
        tag.setAttribute("content", tagDef.content);
      } else {
        // Create a new tag if no existing tag was found
        tag = document.createElement("meta");
        Object.keys(tagDef).forEach((key) => {
          tag.setAttribute(key, tagDef[key]);
        });
        tag.setAttribute("data-vue-controlled", "");
        document.head.appendChild(tag);
      }
    });
  }
}

export function titleFormatter(title) {
  const titleWithSuffix = `${title} | Ways2Well`;

  if (titleWithSuffix.length <= 62) {
    return titleWithSuffix;
  }
  return title;
}
